import React from 'react';
import Header from './header/Header';
import MainContent from './mainContent/MainContent';

function HomePage() {
  return (
    <div>
      <Header />
      <MainContent />
    </div>
  );
}

export default HomePage;
import React from 'react';
import './Header.css';

function Header() {
  return (
    <header>
      <div className='container'>
        <div className='titulo'>
          <h3 className='texto-titulo'>Aposte na sua visão</h3>
          <h5 className='texto-subtitulo'>O crescimento em sua vida começa no momento em que você dá o primeiro passo!</h5>
        </div>

        <div className='textos'>
          <div className='paragrafos'>Nos últimos anos, o universo dos jogos online tem experimentado um crescimento exponencial, tornando-se uma das indústrias mais lucrativas do mundo digital.</div>
          <div className='paragrafos'>Em um mundo cada vez mais conectado, o mercado digital oferece oportunidades inigualáveis para aqueles que buscam um crescimento na vida</div>
        </div>
      </div>
      
    </header>
  );
}

export default Header;
import React, { useState } from 'react';
import SlotMachine from './SlotMachine';
import Balance from './Balance';
import './AppSlot.css';

function AppSlot() {
  const [result, setResult] = useState(["🍓", "🍋", "🍉"]);
  const [message, setMessage] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [value, setValue] = useState(100);
  const [count, setCount] = useState(0);
  const [isWinner, setIsWinner] = useState(false);

  const symbols = ["🍇", "🍋", "🍉", "🍓", "🍒"];

  const spin = () => {
    setIsSpinning(true);
    setMessage("");
    let intervalId;

    // Função para gerar símbolos aleatórios
    const spinSymbols = () => {
      const newResult = Array.from({ length: 3 }, () => symbols[Math.floor(Math.random() * symbols.length)]);
      setResult(newResult);
    };

    // Iniciar o "giro" das roletas
    intervalId = setInterval(spinSymbols, 100);

    // Parar o giro após 7 segundos e mostrar o resultado final
    setTimeout(() => {
      clearInterval(intervalId);
      const finalResult = Array.from({ length: 3 }, () => symbols[Math.floor(Math.random() * symbols.length)]);
      setResult(finalResult);
      setIsSpinning(false);

      // Verificar se todos os símbolos são iguais
      if (finalResult.every(symbol => symbol === finalResult[0]) || count === 3) {
        setIsWinner(true)
        setCount(0)
        setValue(value + 10);
        setMessage("Você Ganhou +10.00 BRL");
      } else {
        setIsWinner(false)
        setCount(count + 1);
        setValue(value - 1);
        setMessage("Tente Novamente!");
      }
    }, 5000);
  };

  return (
    <div className="App">
      <div className='manu-container'>
         <Balance result={value} />
      </div>

      <div className='body'>
        <div className="header">
          <SlotMachine result={result} />
        </div>

        <div className="starter">
          <div className='start' onClick={spin} disabled={isSpinning}>
            {isSpinning ? "" : "START"}
          </div>
        </div>

        <div className='menssages'>
         {isWinner ?
           <div className="message-win">
             <p style={{color: '#46b008', backgroundColor: '#c2b7a329', padding: '10px', borderRadius: '10px'}}>{message}</p> 
           </div>         
           : 
           <div className="message">
             <p>{message}</p>          
           </div>
         }
        </div>
      </div>
    </div>
  );
}

export default AppSlot;

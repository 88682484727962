import React from 'react';

function Balance({ result }) {
  return (
    <div className='manu'>
        <div className='menu-title'>
           <h5>SLOT MACHINE</h5>
        </div>

        <div className='menu-balance'>
          <p> {result}.00 BRL</p>

          <div className='bet-title'>
           <h5>BET VALUE</h5>
          </div>

          <div className='menu-value'>
              <p> 1.00 BRL</p>
          </div>
        </div>
       
    </div>
  );
}

export default Balance;

import React from 'react';
import './General.css';
import { AiOutlineCheckCircle } from "react-icons/ai";


function AboutPage() {
  return (
    <div className='components'>
      <div className='component-sucess'>
        <div className='textos-main'>
            <AiOutlineCheckCircle style={{marginLeft:'41%', color:'#087824', marginBottom:'8'}} size={50} />
            <div className='titulo-paragrafos-sucess'>CADASTRO CONCLUÍDO</div>
              <div className='paragrafos-main-sucess'>Em breve, um de nossos consultores entrará em contato com você.</div>
          </div>
      </div>
    </div>
  );
}

export default AboutPage;

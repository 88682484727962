import React, {useState} from 'react';
import './MainContent.css'
import Select from 'react-select';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function MainContent() {
  const navigate = useNavigate();
  const [nome, setNome] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [email, setEmail] = useState('');
  const [usuario, setUsuario] = useState({
      nome: '',
      email: '',
      celular: ''
  });
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [sucess, setSucess] = useState(false);
  const options = [
    { value: 'Padrão', label: 'Padrão' },
    { value: 'Master', label: 'Master' },
    { value: 'Premium', label: 'Premium' },
    { value: 'Platinum', label: 'Platinum' },
    { value: 'Diamond', label: 'Diamond' }
  ];
  const [selectedOption, setSelectedOption] = useState("");
  const handlePhoneInput = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2"); // Formata o DDD
    value = value.replace(/(\d)(\d{4})$/, "$1-$2"); // Formata o número
    setWhatsApp(value);
  };
  const [dados, setDados] = useState('');

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: 'rgb(12, 12, 12)',
      borderColor: state.isFocused ? 'rgb(46 85 140 / 79%)' : 'rgb(46 85 140 / 79%)',
      boxShadow: state.isFocused ? '0 0 0 1px #666' : base.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#666' : '#aaa'
      },
      width: 300, // Ajustando a largura do Select
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: 'rgb(12, 12, 12)',
      color:  'rgba(255, 255, 255, 0.965)'
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? 'rgb(46 85 140 / 79%)' : isFocused ? 'rgb(46 85 140 / 79%)' : null,
      color: isSelected ? ' rgba(255, 255, 255, 0.965)' : ' rgba(255, 255, 255, 0.965)',
      ':active': {
        backgroundColor: 'rgb(12, 12, 12)',
      },
    }),
  };


  const handleChangeSelect = selectedOption => {
    if(selectedOption != null) {
      setSelectedOption(selectedOption);
    } else {
      setSelectedOption("");
    }
  };

  const isFormValid = () => {
    if(nome.trim() !== null && nome.trim() != '' && emailRegex.test(email) && whatsApp !== '' &&  whatsApp !== null && selectedOption != null && selectedOption != ''){
        return true;
    } else {
        return false;
    };
};


async function apiDados() {
  try {
       const response = await axios.get('https://site-cadastro-production.up.railway.app/cadastra/usuario', {
        headers: {
            'API-Key': '',
            'api-type': 'buscarDados',
            'Content-Type': 'application/json'
        }
      });
      return response.data;  // Retorna diretamente os dados
  } catch (error) {
      console.error('Falha ao buscar dados do usuário', error.response ? error.response.statusText : error.message);
      return null;  // Retorna nulo em caso de erro
  }
}

async function handleSubmit(e) {
  e.preventDefault();

  const dadosUsuario = await apiDados();  // Aguarda os dados serem buscados
  if (!dadosUsuario) {
    console.error('Não foi possível obter os dados necessários para continuar.');
    return;
  }

  try {
      const response = await axios.post('https://site-cadastro-production.up.railway.app/cadastra/usuario', {
          nome: nome,
          email: email,
          celular: whatsApp,
          pacote: selectedOption.value
      }, {
          headers: {
              'API-Key': dadosUsuario, // Usa os dados retornados por apiDados
              'api-type': '',
              'Content-Type': 'application/json'
          }
      });

      console.log('Usuário cadastrado com sucesso:', response.data);
      setEmail("");
      setNome("");
      setWhatsApp("");
      setSelectedOption(null);
      setSucess(true);

      navigate('/about');
  } catch (error) {
      console.error('Falha ao cadastrar usuário', error.response ? error.response.statusText : error.message);
  }
}


// async function handleSubmit2(e) {
//   e.preventDefault();
//   const dadosUsuario = await apiDados();

//   if (!dadosUsuario) {
//     console.error('Não foi possível obter os dados necessários para continuar.');
//     return;
//   }

//   await apiDados();
 

//   navigate('/about');
// }

  return (
    <main style={{height:'100%'}}>
      <div className='baneficios-container'>
          <div className='texto-titulo'>Benefícios</div>

          <div style={{display:'flex', paddingLeft:'3%', width:'90%'}}>

            <div className='textos-main'>
              <div className='titulo-paragrafos'>Autonomia</div>
              <div className='paragrafos-main'>Sistema gerenciado por Inteligência Artificial</div>
            </div>

            <div className='textos-main'>
              <div className='titulo-paragrafos'>Flexibilidade</div>
              <div className='paragrafos-main'>Baixo investimento de tempo e totalmente digital</div>
            </div>
          </div>

          <div style={{display:'flex', paddingLeft:'8%', width:'90%',  marginTop:'-8%', marginBottom:'10px'}}>

            <div className='textos-main'>
              <div className='titulo-paragrafos'>Alcance</div>
              <div className='paragrafos-main'>Nacional e Internacional com acesso ilimitado</div>
            </div>

            <div className='textos-main'>
              <div className='titulo-paragrafos'>Baixo Custo</div>
              <div className='paragrafos-main'>Todos os beneficios por menos de 30 reais mensais</div>
            </div>
          </div>   
      </div>

      <div className='table-container'>
          <div className='texto-titulo'>Catálogo de preços</div>
          
          <div>
            <div className='table'>
              <div className='section'>
                  <div className='paragrafos-table'>Pacote Padrão</div>
                  <div className='paragrafo-line'>12X  R$ 29,99  -  Construa até 1 jogo</div>
              </div>

              <div className='section'>
                  <div className='paragrafos-table'>Pacote Master</div>
                  <div className='paragrafo-line'>12X  R$ 41,99  -  Construa até 2 jogos</div>
              </div>

              <div className='section'>
                  <div className='paragrafos-table'>Pacote Premium</div>
                  <div className='paragrafo-line'>12X  R$ 49,99  -  Construa até 3 jogos</div>
              </div>

              <div className='section'>
                  <div className='paragrafos-table'>Pacote Platinum</div>
                  <div className='paragrafo-line-duo'>12X  R$ 64,99  -  5 jogos  + Plataforma Online Personalizada</div>
              </div> 

              <div className='section'>
                  <div className='paragrafos-table'>Pacote Diamond</div>
                  <div className='paragrafo-line-duo'>12X  R$ 84,99  -  10 jogos  + Plataforma Online Personalizada</div>
              </div>                
            </div>
          </div>
      </div>

      <div className='cadastro-container'>
         <div>
            <div className='container-main-duo'>
               <div className='titulo'>
               <h3 className='texto-titulo-main'>Venha fazer parte</h3>
               <h5 className='texto-subtitulo-duo'>Se você tem interesse neste mercado, inscreva-se e embarque nesta nova jornada conosco!</h5>
            </div>
           </div>
           <div className='container-form'> 
              <input type="text" placeholder="Seu nome" value={nome} onChange={(e) => setNome(e.target.value)}/>

              <input type="email" placeholder="Seu E-mail" value={email} onChange={(e) => setEmail(e.target.value)}/> 

              <input type="tel" 
                    placeholder="Seu número de whatsapp" 
                    value={whatsApp} 
                    maxLength={15}
                    onChange={handlePhoneInput}/> 

              <p className='paragrafo-pacote'>Escolha o pacote ideal para você:  {selectedOption.label}</p>
              <Select value={selectedOption} onChange={handleChangeSelect} options={options} placeholder="Selecione um pacote..." isClearable={true} isSearchable={true} styles={customStyles} />
              {
              isFormValid() ?
                  <button className='button' onClick={handleSubmit}>Registrar</button>
              :
                  <button className='desabilitado' disabled={true} >Registrar</button>
              }   
          </div>
         </div>
        </div>

      <div style={{color:"black"}}>-------</div>
    </main>
  );
}

export default MainContent;

import React from 'react';

function SlotMachine({ result }) {
  return (
    <div className='cotainer-selections'>
       <div key={0} style={{ marginTop: '0', marginLeft: '-24px', marginRight: '32px', fontSize: '35px' }}>
          {result[0]}
        </div>
        <div key={1} style={{ marginTop: '0', marginRight: '31px',  fontSize: '35px' }}>
          {result[1]}
        </div>
        <div key={2} style={{ marginTop: '0', fontSize: '35px'}}>
          {result[2]}
        </div>
      {/* {result.map((symbol, index) => (
        <div key={index} style={{ marginTop: '0', marginRight: '35px' }}>
          {symbol}
        </div>
      ))} */}
    </div>
  );
}

export default SlotMachine;
